/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class PaymentMethodPage extends Vue {
	@State('customer') customer: CustomerState | undefined;

	paymentMethod(type: string) {
		switch(type) {
			case 'card':
				this.$router.push({name: 'balance-replenishment-by-card'}).then(r => r);
				break;
			case 'crypto':
				this.$router.push({name: 'balance-replenishment-by-crypto'}).then(r => r);
				break;
			case 'wallet':
				this.$router.push({name: 'balance-replenishment-by-wallet'}).then(r => r);
				break;
			default:
				break;
		}
	}
}
